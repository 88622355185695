import Taro, { request as _request } from "@tarojs/taro-h5";
import { WyToast, getAppId, sentryCaptureMessage } from './utils';

const request_data = {
  cno: getAppId()
};

export default (({ method = 'GET', data = {}, url }) => {
  return _request({
    url: url,
    data: {
      ...request_data,
      ...data
    },
    header: {
      'Content-Type': 'application/json',
      appid: getAppId()
    },
    method: method.toUpperCase()
  }).then(res => {
    const { statusCode, data } = res;
    sentryCaptureMessage(data.data);
    if (statusCode >= 200 && statusCode < 300) {
      if (!(data.errcode === 0 || data.code === '0000')) {
        console.error('error------');
        console.log(data);
        WyToast(data.msg);
      }
      return data;
    }
    throw new Error(`网络请求错误，状态码${statusCode}`);
  });
});