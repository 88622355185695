import Taro from '@tarojs/taro'
import { GLOBAL_CONFIG, isProd } from '@/utils/config'
import { getRequest } from '@/utils/netTool'
import { API, getOtherApiData } from '@/utils/apiList'
import * as Sentry from '@sentry/browser'

const getErrorLogfn = () => {
  Taro.request({
    url: 'https://zhongwenopen.oss-cn-hangzhou.aliyuncs.com/maibu/Tips/sos.txt',
    dataType: 'other',
    responseType: 'text',
    method: 'GET',
    success: ({ data }) => {
      const content = data.split(/[(\r\n)\r\n]+/)
      const container = document.createElement('div')
      container.id = 'error-log-container'
      container.className = 'error-log-container'
      const container2 = document.createElement('div')
      container2.className = 'error-log-content'
      const title = document.createElement('div')
      title.innerHTML = '温馨提示'
      title.className = 'error-log-title'
      container2.appendChild(title)
      const btn = document.createElement('div')
      btn.addEventListener('click', function() {
        document.getElementById('error-log-container').style.display = 'none'
      })
      btn.id = 'error-log-btn'
      btn.className = 'error-log-btn'
      btn.innerHTML = '确定'
      content.forEach(item => {
        const son = document.createElement('p')
        if (item.indexOf('电话：') != -1) {
          console.log(item.split('：'))
          const numberList = item.split('：')
          const kefu = document.createElement('span')
          kefu.innerHTML = numberList[0] + '：'
          const num = document.createElement('a')
          num.innerHTML = numberList[1]
          num.href = 'tel:' + numberList[1]
          son.appendChild(kefu)
          son.appendChild(num)
        } else {
          son.innerHTML = item
        }
        container2.appendChild(son)
      })
      container2.appendChild(btn)
      container.appendChild(container2)
      container.style.display = 'flex'
      document.body.appendChild(container)
    },
    fail: error => {
      console.log('error', error)
    }
  })
}
const LIMIT_AUTH_TIMES = 3
const getLocationSearch = () => {
  let { search, hash } = window.location
  const _index = hash.indexOf('?')
  if (!search && _index > -1) {
    search = hash.substring(_index)
  } else if (search && _index > -1) {
    search += '&' + hash.substring(_index + 1)
  }
  return search
}

const parseLocationSearch = (search = '?') => {
  const param = {}
  search.replace(/([^?&]+)=([^?&]+)/g, function(s, v, k) {
    param[v] = decodeURIComponent(k)
    return k + '=' + v
  })
  return param
}
const WyToast = message => {
  Taro.showToast({
    title: message,
    icon: 'none'
  })
}

const insertPage = (type, sld = Taro.getStorageSync(GLOBAL_CONFIG.wy_sld)) => {
  // const insertPage = (type, sld = getSld()) => {
  let openId = getOpenId()

  if (openId) {
    getRequest(API.insertPage, {
      data: {
        openId,
        type,
        sld,
        cno: getAppId()
      },
      loading: false
    })
  }
}

const insertNovelData = (novelId, chapterId, type, chapterName, sld) => {
  let openId = getOpenId()
  if (openId) {
    getRequest(API.insertNovelData, {
      data: {
        openId,
        // sld: Taro.getStorageSync(GLOBAL_CONFIG.wy_sld),
        novelId,
        chapterId,
        type,
        chapterName,
        cno: getAppId(),
        sld // 统计小说充值具体书籍
      },
      loading: false
    })
  }
}

const getSearchKey = key => {
  let search = window.location.href
  let params = search.indexOf('?') >= 0 ? search.split('?') : []
  let obj = {}
  if (params.length > 0) {
    let qs = params[1].split('&'),
      k = null,
      v = null
    if (params[2]) {
      qs.push(...params[2].split('&'))
    }
    qs.forEach(param => {
      let q = param.split('=')
      k = q[0]
      v = q[1]
      obj[k] = v
    })
  }
  return obj[key]
}
const getSld = (bookObj = {}) => {
  // 为阅读界面的相关接口查询sld，首选从url中找，没有的话从阅读历史记录中查找， 除了投放链接直接进入页面的sld，其他页面跳转到小说阅读页的sld均不统计，用noSld区别
  const { sld: sldSearch, noSld } = parseLocationSearch(getLocationSearch())
  let history = localStorage.getItem(GLOBAL_CONFIG.wy_read_history)
  history = history ? JSON.parse(history) : []
  let sldHistory = history.find(item => item.id == bookObj.id) || {}
  return sldSearch && !noSld ? sldSearch : sldHistory.sld ? sldHistory.sld : -1
}
const getSldForNotReading = () => {
  // 只取最近的一本书，该书如果是通过投放链接进来的有sld就用这个sld，没有sld就是-1
  let history = localStorage.getItem(GLOBAL_CONFIG.wy_read_history)
  history = history ? JSON.parse(history) : []
  const current = history && history.length > 0 ? history[0] : {}
  return current.sld ? current.sld : -1
}
const getSldForActivityVip = () => {
  // 为活动、vip、个人中心充值查询sld，只从url找 没找到-1
  const { sld: sldSearch } = parseLocationSearch(getLocationSearch())
  return sldSearch || -1
}
const getSearchKey2 = key => {
  // let search = window.location.href
  let search = window.location.search
  let params = search.indexOf('?') >= 0 ? search.split('?') : []
  let obj = {}
  if (params.length > 0) {
    let qs = params[1].split('&'),
      k = null,
      v = null
    if (params[2]) {
      qs.push(...params[2].split('&'))
    }
    qs.forEach(param => {
      let q = param.split('=')
      k = q[0]
      v = q[1]
      obj[k] = v
    })
  }
  return obj[key]
}

function isWexin() {
  return navigator.userAgent.toLocaleLowerCase().indexOf('MicroMessenger'.toLocaleLowerCase()) >= 0
}

function getAppId() {
  // 测试Appid wx786f6844482a9cf6 浩宇畅读
  // return 'wxbb0951b91e5d0e0a'   // 域名被封，返回指定appid 还需要修改 jumpWxCertLink
  if (process.env.LZX_TARGET.indexOf('TEST') > -1 && !isWexin()) {
    return 'wxd63c692c4fbc339a'
  }
  // // let testUrl = 'https://wx786f6844482a9cf6-aaa.wxcp.mbookcn.com/index.html'
  // // let appID =  testUrl.match(/\/\/(\S*?)\./)[1]
  // let appID = window.location.href.match(/\/\/(\S*?)\./)[1]
  // if (appID.indexOf('-') > 0) {
  //   appID = appID.split('-')[0]
  // }
  // return appID

  return getAppIdFromDomain()
}

/**
 * - 跳转 `author/index.html` 页面
 */
function jumpWxCertLink() {
  // 跳转微信认证链接
  let href = window.location.href
  let appid = getAppId()
  let pathCache = href.split('#')
  if (pathCache.length > 1) {
    Taro.setStorageSync(GLOBAL_CONFIG.path_cache, pathCache[1])
  } else {
    Taro.setStorageSync(GLOBAL_CONFIG.path_cache, '')
  }
  let certPath = pathCache[0] + '#/pages/cert'

  const { PLATFORM_APPID } = getOtherApiData()
  const params = {
    appid,
    component_appid: PLATFORM_APPID,
    return_uri: encodeURIComponent(certPath)
  }
  const paramsStr = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')

  const { AUTH_URL } = getOtherApiData()
  window.location.href = `${AUTH_URL}?${paramsStr}`
}

/**
 * - 跳转到微信 OAuth2 支付 AppId 授权页面上
 */
function jumpWxPayLink({ appid, sn, date }) {
  // 支付号 和 当前微信 appid不同时 跳授权页，授权页与支付页同域名，可能缓存的有openid
  const { PLATFORM_APPID, WEB_PAY_URL, AUTH_URL, PAY_SERVER_BASE_URL } = getOtherApiData()
  let payPath = `${WEB_PAY_URL}?sn=${sn}&appid=${appid}&date=${date}&payrequestbaseurl=${encodeURIComponent(
    PAY_SERVER_BASE_URL
  )}&readAppId=${getAppId()}&readOpenId=${getOpenId(getAppId())}`
  const params = {
    appid,
    component_appid: PLATFORM_APPID,
    return_uri: encodeURIComponent(payPath),
    targetType: 'pay'
  }
  const paramsStr = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
  console.log(`${AUTH_URL}?${paramsStr}`)
  window.location.href = `${AUTH_URL}?${paramsStr}`
}

/**
 * - 跳转到支付页面
 */
function createWxPayLink(params) {
  // 支付号 和 当前微信 appid相同时，本地有openid 直接跳支付页
  const { WEB_PAY_URL, PAY_SERVER_BASE_URL } = getOtherApiData()
  const locParams = {
    ...params,
    gostep: '-1',
    date: Date.parse(new Date()),
    payrequestbaseurl: encodeURIComponent(PAY_SERVER_BASE_URL)
  }
  const paramsStr = Object.keys(locParams)
    .map(key => `${key}=${locParams[key]}`)
    .join('&')
  console.log(`${WEB_PAY_URL}?${paramsStr}`)
  window.location.href = `${WEB_PAY_URL}?${paramsStr}`
}

function setCookie(value, appId) {
  let newAppId = appId ? appId : getAppId()
  document.cookie = `${newAppId}=${value} ;max-age=315360000 ;expires=${new Date(2030, 4, 8)}`
}
function setOpenidCookie(value, appId) {
  const list = window.location.host.split('.')
  if (list.length === 4) {
    list.shift()
  }
  const domain_ = list.join('.')
  let newAppId = appId ? appId : getAppId()
  document.cookie = `${newAppId}=${value} ;max-age=315360000 ;expires=${new Date(
    2030,
    4,
    8
  )} domain=${domain_}`
}
function getCookie(appId) {
  let q = document.cookie
  if (!q) {
    return null
  }
  q = q.replace(/\s/g, '')
  let query = q.split(';')
  let k,
    v,
    s,
    obj = {}
  for (let i of query) {
    s = i.split('=')
    k = s[0]
    v = s[1]
    obj[k] = v
  }
  return obj[appId ? appId : getAppId()]
}

function isHttpUrl() {
  if (window.location.protocol.length === 5) {
    return true
  }
  return false
}

const checkAuthOpenidTimes = () => {
  let time = sessionStorage.getItem('getopenid_times')
  if (!time) {
    sessionStorage.setItem('getopenid_times', '1')
    return true
  } else {
    time = Number(time) + 1
    sessionStorage.setItem('getopenid_times', String(time))
    if (time >= LIMIT_AUTH_TIMES) {
      Sentry.captureMessage(`auth more than three times and appid=${getAppId()}`, {
        contexts: {
          text: {
            hahah: 22
          }
        },
        level: 1
      })
    }
    return time <= LIMIT_AUTH_TIMES
  }
}
/**
 * 获取微信openId
 */
const getWxOpenId = (fn, appId) => {
  // let code = getSearchKey2('code')
  const { PLATFORM_APPID } = getOtherApiData()
  const { code, component_appid } = parseLocationSearch(getLocationSearch())
  console.log('component_appid', component_appid)
  if (process.env.LZX_TARGET.indexOf('TEST') > -1 && !isWexin()) {
    // 非微信内置环境
    typeof fn === 'function' && fn()
    return
  }
  console.log(428)
  if (!code) {
    jumpWxCertLink()
  } else {
    getRequest(
      API.getWxOpenId,
      {
        // 获取openId
        data: {
          code,
          tppAppId: component_appid || PLATFORM_APPID
        },
        loading: false
      },
      res => {
        if (res && res.length > 10) {
          setCookie(res, appId) // 缓存cookie
          setOpenidCookie(res, appId)
          sessionStorage.setItem(GLOBAL_CONFIG.wy_open_id, res)
          // jumpWxCertLink()
        } else {
          const validAuth = checkAuthOpenidTimes()
          validAuth && jumpWxCertLink()
          if (!validAuth) {
            getErrorLogfn()
          }
          return
        }
        if (!sessionStorage.getItem(GLOBAL_CONFIG.wy_wxcp_info)) {
          getRequest(
            API.queryWxmpInfo,
            {
              loading: false
            },
            sres => {
              sessionStorage.setItem(GLOBAL_CONFIG.wy_wxcp_info, JSON.stringify(sres))
            }
          )
        }
        typeof fn === 'function' && fn()
        // Note: remove `code` in url
        // https://xxx.com/?component_appid=xxx&code=xxx#/pages/mine/mine
        window.location.href = window.location.href.replace(/&?code=[^&#]*($|&|#)/, (_, nextChar) =>
          nextChar === '&' ? '&' : '#'
        )
      },
      () => {
        const validAuth = checkAuthOpenidTimes()
        validAuth && jumpWxCertLink()
        if (!validAuth) {
          getErrorLogfn()
        }
        typeof fn === 'function' && fn()
      }
    )
  }
}
const getmyItem = key => {
  return localStorage.getItem(key)
}

/**
 * 获取随机数
 * */
const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

/**
 * modal弹出框
 * @param {string} title 标题
 * @param {string} content 描述
 * @param {function} fn 回调函数
 */
const wyShowModal = (title, content, fn) => {
  Taro.showModal({
    title,
    content,
    confirmColor: 'rgba(103, 219, 236, 1)',
    success: res => {
      if (res.confirm) {
        typeof fn && fn()
      }
    }
  })
}

/**获取用户详细信息  公号平台未使用 */
// function getUserDetail(uid, fn) {
//   getRequest(API.getMyUserInfo, {
//     data: {
//       uid
//     },
//     loading: false
//   }, (res) => {
//     Taro.setStorage({
//       key: GLOBAL_CONFIG.wy_user_info,
//       data: JSON.stringify(res)
//     })
//     typeof fn === 'function' && fn(uid, res)
//   })
// }

/**
 * 格式化时间戳 格式为：YYYY-mm-dd hh:mm:ss
 */
const formateDate = (timestamp, flag = true) => {
  let date = new Date(timestamp)
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  let d = date.getDate()
  let ymd = y + '-' + m + '-' + d
  if (flag === false) {
    return ymd
  }
  let h = date.getHours()
  let min = date.getMinutes()
  let s = date.getSeconds()
  return ymd + ' ' + h + ':' + min + ':' + s
}

/**
 * 格式化
 */
function myParse(json = {}) {
  if (!json) {
    return {}
  }
  return JSON.parse(decodeURIComponent(json))
}

/**
 * json字符串化
 */
function myStringify(obj) {
  return encodeURIComponent(JSON.stringify(obj))
}

/**
 * 深拷贝
 */
function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * 从一个数组中获取随机个对象
 * @param arr 数组
 * @param num 整数
 */
function getRandomObj(arr, num = 6) {
  let newList = []
  let newArray = deepCopy(arr)
  for (let i in arr) {
    if (i == num) {
      break
    }
    let newIndex = getRandom(0, arr.length - (1 + parseInt(i)))
    newList.push(newArray[newIndex])
    newArray.splice(newIndex, 1)
  }
  return newList
}

/**
 * 返回一个新对象，对象里面的键从数组中获取，例如：{a:1,age:26,name:'doudou'} [a,name],那最后会返回{a:1,name:'doudou'}
 * 如果数组里面是[a,{test: 'name'}]，那会返回：[a:1,test:'doudou']
 * 数组中的键也可以做个映射，例如上面的{test:'name'}
 * @param obj 对象
 * @param arr 数组
 */
function getObjFromArrays(obj = {}, arr = [], source = {}) {
  let newObj = {}

  let k, v, ks, vs, cks, vk
  for (let i in arr) {
    k = arr[i]
    v = obj[k] ? String(obj[k]) : null
    if (typeof k === 'string') {
      if (v) {
        newObj[k] = v
      }
    } else {
      ks = Object.keys(k)
      vs = Object.values(k)
      for (let ci in vs) {
        cks = ks[ci]
        vk = String(obj[vs[ci]])
        if (vk) {
          newObj[cks] = vk
        }
      }
    }
  }
  return { ...newObj, ...source }
}

/**
 * 获取用户的openId
 */
function getOpenId(appId) {
  // o2-4jvz9cOK0RFwP0G7i0B1Rwayo 浩宇
  // if (!isWexin() && !appId) {
  //   return 'o2-4jvz9cOK0RFwP0G7i0B1Rwayo'
  // }
  // return null
  // return 'oh-Cb5x3J-Jasmv3jBk4oLB3RNSA'
  let openId = getCookie(appId)
  if (openId && openId.length > 10) {
    return openId
  } else {
    //getWxOpenId()
    return null
    // ansycSleep(2000).then(() => {
    //   return
    // })
  }
}

// function syncSleep(delay) {
//   var start = new Date().getTime()
//   while (new Date().getTime() - start < delay) {
//     continue
//   }
// }

// function ansycSleep(time) {
//   return new Promise(resolve => setTimeout(resolve, time))
// }

/**
 * 返回目标平台标识， 中文   海草
 */
function getTarget() {
  return process.env.LZX_TARGET
}

/**
 * 阻止疯狂点击
 */
function stopClick(_this) {
  if (_this.isClick) {
    return true
  }
  _this.isClick = true
  _this.timer = setTimeout(() => {
    clearTimeout(_this.timer)
    _this.isClick = false
  }, 500)
}

function isIos() {
  return false
}

/**
 * 判断是否是安卓
 */
function isAndroid() {
  return Taro.getStorageSync('ios') ? false : true
}

/**
 * 自定义log
 */
function wyLog(param) {
  if (!isProd) {
    let str = ''
    for (let i in param) {
      if (typeof param[i] === 'string') {
        str += param[i]
      } else {
        console.log(param[i])
      }
    }
    str && console.log(str)
  }
}

function getNovelId() {
  let result = localStorage.getItem(GLOBAL_CONFIG.wy_read_history)
  if (!result) return 0
  if (result) {
    result = JSON.parse(result)

    if (result.data) {
      result = result.data
      result = result ? JSON.parse(result) : result
    }
    result = result.filter(v => v.title)
    let newList = []
    for (let i in result) {
      if (newList.findIndex(v => v.id == result[i].id) === -1) {
        newList.push(result[i])
      }
    }
    if (newList.length == 0) return 0
    return parseInt(newList[0].id)
  }
}

function elementInViewport(el) {
  var top = el.offsetTop
  var left = el.offsetLeft
  var width = el.offsetWidth
  var height = el.offsetHeight
  // console.log('top', top)
  console.log('el.offsetTop', el.offsetTop)
  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    left += el.offsetLeft
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  )
}

function dateFormat(date, format) {
  if (typeof date === 'string') {
    var mts = date.match(/(\/Date\((\d+)\)\/)/)
    if (mts && mts.length >= 3) {
      date = parseInt(mts[2])
    }
  }
  date = new Date(date)
  if (!date || date.toUTCString() == 'Invalid Date') {
    return ''
  }
  var map = {
    M: date.getMonth() + 1, //月份
    d: date.getDate(), //日
    h: date.getHours(), //小时
    m: date.getMinutes(), //分
    s: date.getSeconds(), //秒
    q: Math.floor((date.getMonth() + 3) / 3), //季度
    S: date.getMilliseconds() //毫秒
  }

  format = format.replace(/([yMdhmsqS])+/g, function(all, t) {
    var v = map[t]
    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v
        v = v.substr(v.length - 2)
      }
      return v
    } else if (t === 'y') {
      return (date.getFullYear() + '').substr(4 - all.length)
    }
    return all
  })
  return format
}

function getAppIdFromDomain() {
  if (process.env.LZX_TARGET.indexOf('TEST') > -1 && !isWexin()) {
    return 'wxd63c692c4fbc339a'
  }

  // 取wx开头的，最长的片段，去掉结尾-xx后缀如果有的话。
  const arr = window.location.hostname.split('.')
  let found = ''
  let foundLength = 0
  for (let i = 0; i < Math.max(0, arr.length - 3); i++) {
    const str = arr[i]
    if (str.indexOf('wx') === 0 && str.length > foundLength) {
      foundLength = str.length
      found = str.replace(/-.*$/, '')
    }
  }
  return found
}

function getQWKeys() {
  const { corpid, external_userid } = parseLocationSearch(getLocationSearch())
  return { corpid, external_userid }
}
function sentryCaptureMessage(data) {
  if (data && data.code == '7000') {
    Sentry.withScope(scope => {
      scope.setExtra('url', window.location.href)
      Sentry.captureMessage(`[api error]: ${data.msg || '接口异常'}`)
    })
  }
}
export {
  WyToast,
  getSearchKey,
  getSearchKey2,
  getSld,
  getSldForNotReading,
  getSldForActivityVip,
  getmyItem,
  getRandom,
  wyShowModal,
  formateDate,
  myParse,
  myStringify,
  deepCopy,
  getRandomObj,
  getObjFromArrays,
  stopClick,
  isIos,
  wyLog,
  insertPage,
  getWxOpenId,
  insertNovelData,
  getAppId,
  getAppIdFromDomain,
  isAndroid,
  getOpenId,
  getNovelId,
  getTarget,
  isHttpUrl,
  getLocationSearch,
  parseLocationSearch,
  elementInViewport,
  dateFormat,
  jumpWxPayLink,
  createWxPayLink,
  getQWKeys,
  sentryCaptureMessage
}
