import Taro, { Component } from "@tarojs/taro-h5";
const { getAppId, sentryCaptureMessage } = require('./pages/libs/utils/utils.js');
require('./pages/libs/utils/sentry');
const umeng = require('./pages/libs/utils/umeng');
// import sensors from './sensorsdata.es6.min.js'
// import { getAppId } from '@/utils/utils'
// import { API } from '@/utils/apiList'
// import { GLOBAL_CONFIG, GLOBAL_CONFIG_VALUE } from '@/utils/config'

const UserService = require('./pages/libs/services/UserService').default;
const RouterService = require('./pages/libs/services/RouterService').default;

import './app.scss';

// eslint-disable-next-line import/no-commonjs
import Nerv from 'nervjs';
import { Router, createHistory, mountApis } from '@tarojs/router';
Taro.initPxTransform({
  "designWidth": 750,
  "deviceRatio": {
    "640": 1.17,
    "750": 1,
    "828": 0.905
  }
});

const _taroHistory = createHistory({
  mode: "hash",
  basename: "/",
  customRoutes: {},
  firstPagePath: "/pages/bookMall/bookMall"
});

mountApis({
  "basename": "/",
  "customRoutes": {}
}, _taroHistory);
const { GLOBAL_CONFIG, GLOBAL_CONFIG_VALUE } = require('./pages/libs/utils/config.js');
// eslint-disable-next-line import/no-commonjs
// const {getAppId} = require('@/utils/utils.js')   //getUserInfo, getSearchKey,
// eslint-disable-next-line import/no-commonjs
// const {API} = require('@/utils/apiList')
// const {getRequest} = require( '@/utils/netTool')

// 如果需要在 h5 环境中开启 React Devtools
// 取消以下注释：
if (process.env.NODE_ENV !== 'production' && true) {
  require('nerv-devtools');
}
RouterService.onBefore('componentDidMount', async () => {
  await UserService.init();
  umeng.trackPV();
});
RouterService.onBefore('componentWillMount', async () => {
  await UserService.init();
});
RouterService.onBefore('componentDidShow', async () => {
  await UserService.init();
});

//如果不想要加载中的状态，直接注释掉整个render的hook
RouterService.onBefore('render', function () {
  if (UserService.isReady()) {
    return;
  }
  UserService.init().then(() => {
    this.forceUpdate();
  });
  return '加载中...';
});

class App extends Component {
  componentDidMount() {
    // 接入友盟埋点
    umeng.install();

    console.log('app mounted 39');
    const openid = this.getOpenId();
    console.log('openid', openid);
    if (openid) {
      this.setOpenidCookie(openid);
    }
    this.componentDidShow();
  }

  componentWillUnmount() {
    this.componentDidHide();
  }
  componentDidShow() {
    this.init();
    // this.queryHistory()
    let res = Taro.getStorageSync(GLOBAL_CONFIG.wy_uuid);
    if (!res) {
      let uuid = this.getUuid();
      // 缓存uuid
      Taro.setStorage({
        key: GLOBAL_CONFIG.wy_uuid,
        data: uuid
      });
    }
  }
  componentDidHide() {}
  componentDidCatchError() {}
  setOpenidCookie = (value, appId) => {
    const list = window.location.host.split('.');
    if (list.length === 4) {
      list.shift();
    }
    const domain_ = list.join('.');
    let newAppId = appId ? appId : this.getAppId();
    document.cookie = `${newAppId}=${value} ;max-age=315360000 ;expires=${new Date(2030, 4, 8)} ;domain=${domain_}`;
  };
  getCookie = appId => {
    let q = document.cookie;
    if (!q) {
      return null;
    }
    q = q.replace(/\s/g, '');
    let query = q.split(';');
    let k,
        v,
        s,
        obj = {};
    for (let i of query) {
      s = i.split('=');
      k = s[0];
      v = s[1];
      obj[k] = v;
    }
    return obj[appId ? appId : this.getAppId()];
  };
  getOpenId = appId => {
    let openId = this.getCookie(appId);
    if (openId && openId.length > 10) {
      return openId;
    } else {
      return null;
    }
  };
  getAppId = () => {
    return getAppId();
  };
  config = {
    pages: ["/pages/bookMall/bookMall", //首页
    "/pages/bookRack/bookRack", //书架
    "/pages/bookMall/leaderBoard/index", //限免
    "/pages/bookMall/blockList/index", //区块列表
    "/pages/bookMall/searchNovel/index", //搜索
    "/pages/bookMall/history/index", //阅读历史
    // 'pages/bookMall/novelDetail/novelDetail',
    "/pages/bookMall/bookList/bookList", //目录页
    "/pages/bookMall/read/read", //阅读页
    "/pages/bookMall/hotSearch/index", //热搜
    // 'pages/bookMall/readSetting/index',
    "/pages/bookMall/autoBuy/index", //自动购买
    "/pages/bookMall/activity/index", //活动专题
    "/pages/bookMall/giftActivity/index", // 直赠活动
    // 'pages/category/category',
    "/pages/category/categoryDetail", //分类
    "/pages/mine/mine", //个人中心
    // 'pages/mine/account/index',
    "/pages/mine/consumeList/index", //消费记录
    "/pages/mine/rechargeList/index", //充值记录
    "/pages/mine/recharge/index", //充值页
    // 'pages/mine/autobuy/index',
    // 'pages/mine/subsciption/index',
    "/pages/mine/lateRead/index", //最近阅读
    "/pages/bookMall/ranklist/index", //排行榜
    "/pages/mine/feedback/index", //意见反馈
    "/pages/mine/sign/index", //签到
    "/pages/mine/pay/index", //支付
    "/pages/mine/vip/index", //VIP购买
    "/pages/mine/freeCharge/index", //赠币
    "/pages/cert", //鉴权
    "/pages/bookMall/whitePage/index" // 跳转空白页
    ],
    window: {
      backgroundTextStyle: 'light',
      navigationBarBackgroundColor: '#fff',
      navigationBarTitleText: '海草阅读',
      navigationBarTextStyle: 'black'
    }

    /**
     * 处理h5预览逻辑
     */
    // actionPreview = () => {
    //   let cno = getSearchKey(GLOBAL_CONFIG.wy_cno)
    //   if (cno) { // 存在渠道，就缓存渠道,以下类似
    //     Taro.setStorage({
    //       key: GLOBAL_CONFIG.wy_cno,
    //       data: getSearchKey(GLOBAL_CONFIG.wy_cno)
    //     })
    //   } else {
    //     Taro.removeStorage({key: GLOBAL_CONFIG.wy_cno})
    //   }
    //   let platform = getSearchKey(GLOBAL_CONFIG.wy_platform)
    //   if (platform) { // 平台
    //     Taro.setStorage({
    //       key: GLOBAL_CONFIG.wy_platform,
    //       data: getSearchKey(GLOBAL_CONFIG.wy_platform)
    //     })
    //   } else {
    //     Taro.removeStorage({key: GLOBAL_CONFIG.wy_platform})
    //   }
    //   let columnId = getSearchKey(GLOBAL_CONFIG.wy_columnId)
    //   if (columnId) { // 分栏Id
    //     Taro.setStorage({
    //       key: GLOBAL_CONFIG.wy_columnId,
    //       data: getSearchKey(GLOBAL_CONFIG.wy_columnId)
    //     })
    //   } else {
    //     Taro.removeStorage({key: GLOBAL_CONFIG.wy_columnId})
    //   }
    // }
  };queryHistory = () => {
    const openid = this.getOpenId();
    const { API } = require('@/utils/apiList');
    Taro.request({
      url: API.queryReadHistory,
      data: {
        openid: openid
      },
      header: {
        'content-type': 'application/json',
        mode: 'no-cors', // 支持h5跨域
        appid: this.getAppId()
      }
    }).then(({ data }) => {
      sentryCaptureMessage(data);
      console.log('220 app.jsx');
      localStorage.setItem(GLOBAL_CONFIG.wy_read_history, JSON.stringify(data.data));
    });
  };
  init = () => {
    // this.actionPreview()
    // 清除标记首次进入页面
    Taro.removeStorage({
      key: GLOBAL_CONFIG.wy_first_active
    });
    // 获取用户信息
    // getUserInfo()
    const res = Taro.getSystemInfoSync();
    let model = res.model || '';
    let isIos = model.toLocaleLowerCase().indexOf('iphone') >= 0;
    Taro.setStorageSync('ios', isIos);

    let wxcpInfo = sessionStorage.getItem(GLOBAL_CONFIG.wy_wxcp_info);
    if (wxcpInfo === 'null' || !wxcpInfo) {
      // 获取微信公众号信息
      // const { getAppId } = require('@/utils/utils.js')
      const { API } = require('@/utils/apiList');
      Taro.request({
        url: API.queryWxmpInfo,
        data: {
          sdkVersion: GLOBAL_CONFIG_VALUE.version,
          platform: GLOBAL_CONFIG_VALUE.platform,
          cno: this.getAppId()
        },
        header: {
          'content-type': 'application/json',
          mode: 'no-cors', // 支持h5跨域
          appid: this.getAppId()
        }
      }).then(({ data }) => {
        sentryCaptureMessage(data);
        let obj = data.data || {};
        sessionStorage.setItem(GLOBAL_CONFIG.wy_wxcp_info, JSON.stringify(obj));
      });
    }
  };

  /**
   * 获取唯一标识符
   */
  getUuid = () => {
    let s = [];
    let hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';
    let uuid = s.join('');
    return uuid;
  };

  // 在 App 类中的 render() 函数没有实际作用
  // 请勿修改此函数
  render() {
    return <Router mode={"hash"} history={_taroHistory} routes={[{
      path: '/pages/bookMall/bookMall',
      componentLoader: () => import( /* webpackChunkName: "bookMall_bookMall" */'./pages/bookMall/bookMall'),
      isIndex: true
    }, {
      path: '/pages/bookRack/bookRack',
      componentLoader: () => import( /* webpackChunkName: "bookRack_bookRack" */'./pages/bookRack/bookRack'),
      isIndex: false
    }, {
      path: '/pages/bookMall/leaderBoard/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_leaderBoard_index" */'./pages/bookMall/leaderBoard/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/blockList/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_blockList_index" */'./pages/bookMall/blockList/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/searchNovel/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_searchNovel_index" */'./pages/bookMall/searchNovel/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/history/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_history_index" */'./pages/bookMall/history/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/bookList/bookList',
      componentLoader: () => import( /* webpackChunkName: "bookMall_bookList_bookList" */'./pages/bookMall/bookList/bookList'),
      isIndex: false
    }, {
      path: '/pages/bookMall/read/read',
      componentLoader: () => import( /* webpackChunkName: "bookMall_read_read" */'./pages/bookMall/read/read'),
      isIndex: false
    }, {
      path: '/pages/bookMall/hotSearch/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_hotSearch_index" */'./pages/bookMall/hotSearch/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/autoBuy/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_autoBuy_index" */'./pages/bookMall/autoBuy/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/activity/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_activity_index" */'./pages/bookMall/activity/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/giftActivity/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_giftActivity_index" */'./pages/bookMall/giftActivity/index'),
      isIndex: false
    }, {
      path: '/pages/category/categoryDetail',
      componentLoader: () => import( /* webpackChunkName: "category_categoryDetail" */'./pages/category/categoryDetail'),
      isIndex: false
    }, {
      path: '/pages/mine/mine',
      componentLoader: () => import( /* webpackChunkName: "mine_mine" */'./pages/mine/mine'),
      isIndex: false
    }, {
      path: '/pages/mine/consumeList/index',
      componentLoader: () => import( /* webpackChunkName: "mine_consumeList_index" */'./pages/mine/consumeList/index'),
      isIndex: false
    }, {
      path: '/pages/mine/rechargeList/index',
      componentLoader: () => import( /* webpackChunkName: "mine_rechargeList_index" */'./pages/mine/rechargeList/index'),
      isIndex: false
    }, {
      path: '/pages/mine/recharge/index',
      componentLoader: () => import( /* webpackChunkName: "mine_recharge_index" */'./pages/mine/recharge/index'),
      isIndex: false
    }, {
      path: '/pages/mine/lateRead/index',
      componentLoader: () => import( /* webpackChunkName: "mine_lateRead_index" */'./pages/mine/lateRead/index'),
      isIndex: false
    }, {
      path: '/pages/bookMall/ranklist/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_ranklist_index" */'./pages/bookMall/ranklist/index'),
      isIndex: false
    }, {
      path: '/pages/mine/feedback/index',
      componentLoader: () => import( /* webpackChunkName: "mine_feedback_index" */'./pages/mine/feedback/index'),
      isIndex: false
    }, {
      path: '/pages/mine/sign/index',
      componentLoader: () => import( /* webpackChunkName: "mine_sign_index" */'./pages/mine/sign/index'),
      isIndex: false
    }, {
      path: '/pages/mine/pay/index',
      componentLoader: () => import( /* webpackChunkName: "mine_pay_index" */'./pages/mine/pay/index'),
      isIndex: false
    }, {
      path: '/pages/mine/vip/index',
      componentLoader: () => import( /* webpackChunkName: "mine_vip_index" */'./pages/mine/vip/index'),
      isIndex: false
    }, {
      path: '/pages/mine/freeCharge/index',
      componentLoader: () => import( /* webpackChunkName: "mine_freeCharge_index" */'./pages/mine/freeCharge/index'),
      isIndex: false
    }, {
      path: '/pages/cert',
      componentLoader: () => import( /* webpackChunkName: "cert" */'./pages/cert'),
      isIndex: false
    }, {
      path: '/pages/bookMall/whitePage/index',
      componentLoader: () => import( /* webpackChunkName: "bookMall_whitePage_index" */'./pages/bookMall/whitePage/index'),
      isIndex: false
    }]} customRoutes={{}} />;
  }

  constructor(props, context) {
    super(props, context);
    Taro._$app = this;
  }

}

Nerv.render(<App />, document.getElementById('app'));