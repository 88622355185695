// 不要编辑domainConfig.js
// 修改配置请编辑它的兄弟domainConfig.template.js
export const BACKEND_BASE_URL = 'https://newapi.mbookcn.net'
export const TEMP_PLATFORM = 'wx9f35ea5ec93a2caf' // 三方平台id
export const BILLING_APP = 'https://zhongwenopen.oss-cn-hangzhou.aliyuncs.com/config/open.json' // 计费公号ID地址
export const WXCP_URL = 'https://%APPID%.mp.qinrenmi.com/' // 指定公号地址
export const ICP_NO = '粤ICP11047445号' // ICP证号
export const SP_NO = '' // SP证号
export const WEB_AUTHOR_URL = 'https://auth.mbookcn.net/author/index.html'
export const WEB_PAY_URL = 'https://auth.mbookcn.net/author/pay/index.html' // 公众号计费URL
